import { Component } from '@angular/core';
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "../../../shared/components/button/button.component";

@Component({
  selector: 'app-features',
  standalone: true,
    imports: [
        AppSvgIconComponent,
        ButtonComponent
    ],
  templateUrl: './features.component.html',
  styleUrl: './features.component.scss'
})
export class FeaturesComponent {

}
