<div id="home" class="container-fluid hero-section-height flex flex-col">

  <div class="grow container mx-auto px-4 py-4 flex flex-col justify-center">
    <div class="grid grid-cols-1 lg:grid-cols-2">
      <div class="flex">
        <div class="flex flex-col gap-8 max-w-xl justify-center items-center lg:items-start">
          <div class="flex flex-col text-h4 text-primary-500 gap-2 text-center lg:text-left">
            <p class="">Streamline Your Fleet Management</p>
            <p>and Driver Compliance with Ease</p>
          </div>
          <p class="text-body1 text-primary-500 text-center lg:text-left">
            We help you stay compliant and ready for any FMCSA/DOT audit or inspection
          </p>
          <app-button onclick="onSignUp">Sign up</app-button>
        </div>
      </div>

      <div class="hidden lg:flex justify-center items-center">
        <img src="assets/images/hero-image.png" alt="mockup">
      </div>

    </div>


  </div>

  <div class="flex flex-col gap-8 items-center bg-blue-50 px-4 py-12">
    <p class="text-neutral-500 ">Happy Clients</p>
    <img src="assets/images/companies.png" alt="logos">
  </div>
</div>