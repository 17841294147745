<div class="container mx-auto px-4 py-14 flex flex-col ">
    <div class="flex  flex-col  items-center gap-6">
        <div class="flex items-center gap-3">
            <app-svg-icon [size]="48" src="assets/branding/logo.svg"></app-svg-icon>
            <div class="flex flex-col text-subtitle2 text-primary-500">
                <div>Your Safety</div>
                <div>Compliance</div>
            </div>
        </div>
        <img class="h-20 w-20 mt-14" src="../../../../../../assets/images/mail-success%201.png">
        <p class="text-body2">Thank you for reaching out to us!</p>
        <div class="flex flex-col text-body2 text-success-500 text-center">
            <div>Your message has been received. We'll carefully review the information you've provided and aim </div>
            <div>to respond promptly.</div>
        </div>
        <a class="text-info-500 underline" href="/home">Go to home</a>
    </div>
</div>
