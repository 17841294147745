import {inject, Injectable} from '@angular/core';
import {Subject, timeout} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  router = inject(Router);

  private scrollToSectionSource = new Subject<string>();
  scrollToSection$ = this.scrollToSectionSource.asObservable();

  scrollTo(sectionId: string) {
    this.router.navigate(['/']).then(() => {
      this.scrollToSectionSource.next(sectionId);
    });
  }
}
