import { Component } from '@angular/core';
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {ServiceComponent} from "../service/service.component";

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    ButtonComponent,
    ServiceComponent
  ],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss'
})
export class ServicesComponent {

}
