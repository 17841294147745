<header class="container-fluid bg-white shadow-1">
  <div class="container mx-auto px-4 top-bar-height flex flex-col justify-center">
    <div class="w-full flex justify-between items-center">
      <div class="flex items-center gap-3">
        <app-svg-icon [size]="48" src="assets/branding/logo.svg"></app-svg-icon>
        <div class="flex flex-col">
          <div class="text-subtitle2 text-primary-500">Your Safety</div>
          <div class="text-subtitle2 text-primary-500">Compliance</div>
        </div>
      </div>

      <div class="hidden lg:flex gap-5">
        <a href="home" class="nav-link">Home</a>
        <a (click)="onNavClicked('services')" class="nav-link">Services</a>
        <a (click)="onNavClicked('features')" class="nav-link">Features</a>
        <a routerLink="/contact-us" class="nav-link">Contact us</a>
      </div>
      <div class="flex items-center justify-center">
        <app-button (click)="onSignupClicked()">Sign up</app-button>
        <app-button appearance="textType" (click)="onLoginClicked()">Login</app-button>
      </div>
    </div>
  </div>
</header>
