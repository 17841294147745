import { Injectable } from '@angular/core';
import {BaseApiService} from "../../../shared/base/base-api.service";
import {URLs} from "../../../core/api/urls";

@Injectable({
  providedIn: 'root'
})
export class ContactUsService extends BaseApiService {

  submitContactUs(request: any) {
    return this.post(URLs.contactUs, request);
  }
}
