import {CommonModule} from '@angular/common';
import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {
  OutlineTextInputComponent
} from "../../../../shared/components/outline-text-input/outline-text-input/outline-text-input.component";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {TopBarComponent} from '../../../home/top-bar/top-bar.component';
import {FooterComponent} from '../../../home/footer/footer.component';
import {BaseComponent} from "../../../../shared/base/base-component";
import {ContactUsService} from "../../data/contact-us.service";
import {State} from "../../../../shared/base/base-state";

@Component({
  selector: 'app-contact-us',
  standalone: true,
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
  imports: [ReactiveFormsModule, CommonModule, ButtonComponent, OutlineTextInputComponent, AppSvgIconComponent, TopBarComponent, FooterComponent]
})
export class ContactUsComponent extends BaseComponent implements OnInit {

  contactUsService = inject(ContactUsService);
  formBuilder = inject(FormBuilder);

  contactUsForm!: FormGroup;

  contactusState = new State<any>();

  ngOnInit(): void {
    this.contactUsForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: [''],
      phoneNumber: ['', Validators.required],
      email: ['', Validators.email],
      message: ['', Validators.required],
    });
  }


  onContactUsSubmit() {
    let request = this.contactUsForm.value;
    this.executeRequest({
      state: this.contactusState,
      request: this.contactUsService.submitContactUs(request),
      onSuccess: response => {
        this.contactUsForm.reset();
        this.router.navigate(['/contact-us-success']);
      },
      handleSuccess: true,
      successMessage: 'Thank you for contacting us, we will get back to you as soon as possible.',
     
    });
  }
}
