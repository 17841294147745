<footer class="container-fluid bg-neutral-50">
  <div class="container mx-auto px-4 flex flex-col gap-4">
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6 py-14">
      <div class="flex flex-col col-span-2 gap-2">
        <div class="flex items-center">
          <app-svg-icon src="assets/branding/logo.svg" class="h-10 w-8"></app-svg-icon>
          <div class="flex flex-col">
            <div class="text-subtitle2 text-primary-500">Your Safety</div>
            <div class="text-subtitle2 text-primary-500">Compliance</div>
          </div>
        </div>

        <div class="text-subtitle text-neutral-400">
          Streamline Your Fleet Management and Compliance Management
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <app-footer-link>Home</app-footer-link>
        <app-footer-link>Services</app-footer-link>
        <app-footer-link>Features</app-footer-link>
      </div>
      <div class="flex flex-col gap-4">
        <app-footer-link>Request a Demo</app-footer-link>
        <app-footer-link>Contact us</app-footer-link>
      </div>

      <div class="flex flex-col gap-4">
        <div class="flex items-center gap-3">
          <p> <app-svg-icon [size]="20" src="assets/icons/location.svg"></app-svg-icon></p>
          <p class="text-body2">15001, South Airport Way
            Manteca, California,
            United States</p>
        </div>
        <div class="flex items-center gap-3">
          <p> <app-svg-icon [size]="20" src="assets/icons/contact.svg"></app-svg-icon></p>
          <p class="text-body2">(408) 482-6367</p>
        </div>
        <div class="flex items-center gap-3">
          <p> <app-svg-icon [size]="20" src="assets/icons/mail.svg"></app-svg-icon></p>
          <p class="text-body2"> {{ 'yoursafetycompliance@gmail.com' }}</p>
        </div>
      </div>
    </div>
    <div class="border-b border-neutral-200"></div>
    <div class="flex gap-3 my-4 sm:justify-end sm:mt-0">
      <a href="https://www.facebook.com/yoursafetycompliance" class="text-gray-500" target="_blank">
        <app-svg-icon src="assets/icons/facebook.svg"></app-svg-icon>
      </a>
      <a href="https://www.instagram.com/yoursafetycompliance" class="text-gray-500" target="_blank">
        <app-svg-icon src="assets/icons/instagram.svg"></app-svg-icon>
      </a>
    </div>
  </div>
</footer>