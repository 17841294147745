<div class="flex gap-3 items-center">

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 11" fill="currentColor" class="w-4 text-green-500">
    <path
      d="M5.00011 10.6134L0.860107 6.47336L2.74677 4.58669L5.00011 6.84669L11.5868 0.253357L13.4734 2.14002L5.00011 10.6134Z"
      fill="currentColor"/>
  </svg>

  <p class="text-md">{{ title() }}</p>

  @if (info()) {
    <svg [tooltip]="info()!" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
         class="w-4 h-4 text-blue-500 cursor-pointer">
      <path stroke-linecap="round" stroke-linejoin="round"
            d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"/>
    </svg>
  }

</div>
