import {Routes} from '@angular/router';
import {HomeComponent} from './features/home/home.component';
import {PageNotFoundComponent} from './features/layouts/page-not-found/page-not-found.component';
import {ContactUsComponent} from './features/contact-us/presentation/pages/contact-us.component';
import {AppComponent} from "./app.component";
import {LayoutComponent} from "./features/layouts/layout/layout.component";
import {
  ContactUsSuccessComponent
} from './features/contact-us/presentation/pages/contact-us-success/contact-us-success.component';
import {RequestDemoComponent} from './features/home/try-our-platform/request-demo/presentation/request-demo.component';
import {
  RequestDemoSuccessComponent
} from './features/home/try-our-platform/request-demo-success/request-demo-success.component';
import {TryOurPlatformComponent} from './features/home/try-our-platform/try-our-platform.component';

export const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {
    path: '', component: LayoutComponent, children: [
      {path: 'home', component: HomeComponent},
      {path: 'contact-us', component: ContactUsComponent},
      {path: 'contact-us-success', component: ContactUsSuccessComponent},
      {path: 'try-out-platform', component: TryOurPlatformComponent},
      {path: 'request-for-demo', component: RequestDemoComponent},
      {path: 'request-demo-success', component: RequestDemoSuccessComponent},
    ]
  },
  {path: '**', component: PageNotFoundComponent},
];

