import {Component, EventEmitter, inject, input, Input, OnInit, Output} from '@angular/core';
import {NgClass} from "@angular/common";
import {SpinnerComponent} from "../spinner/spinner.component";
import {ControlContainer, FormControl, FormGroup, FormGroupDirective} from "@angular/forms";
import {AppSvgIconComponent} from "../app-svg-icon/app-svg-icon.component";

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    NgClass,
    SpinnerComponent,
    AppSvgIconComponent
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  type = input<'button' | 'submit' | 'reset'>('button');
  disabled = input<boolean>(false);
  fullWidth = input<boolean>(false);
  appearance = input<'textType' | 'primary' | 'outline' | 'primaryRounded' | 'outlineRounded'>('primary');
  loading = input<boolean>(false);
  iconSrc = input<string | null>();
  iconColor = input<string | null>('text-white');
  buttonColor = input<string>('bg-primary-500');
  outlineColor = input<string>('border-primary-500');
  textButtonColor = input<string>('text-primary-500 ');

  @Output() buttonClick = new EventEmitter<void>();

  formGroupDirective = inject(FormGroupDirective, {optional: true});

  getButtonClass(): string {
    let buttonClass = 'inline-flex items-center justify-center px-6 py-3 text-button leading-5 transition-all duration-200';
    switch (this.appearance()) {
      case 'outline':
        buttonClass = `${buttonClass} ${this.textButtonColor()}  rounded-md bg-white border ${this.outlineColor()} outline-none`;
        break;
      case 'outlineRounded':
        buttonClass = `${buttonClass}  ${this.textButtonColor()} rounded-full bg-white border ${this.outlineColor()} outline-none`;
        break;
      case 'textType':
        buttonClass = `${buttonClass}  ${this.textButtonColor()} rounded-md border border-transparent rounded-md bg-white outline-none focus:outline-none`;
        break;
      case 'primaryRounded':
        buttonClass = `${buttonClass}  text-white rounded-full ${this.buttonColor()}`;
        break;
      case 'primary':
      default:
        buttonClass = `${buttonClass} text-white rounded-md ${this.buttonColor()}`;
        break;
    }

    if (this.fullWidth()) {
      buttonClass = `${buttonClass} w-full`;
    }

    return buttonClass;
  }

  onClick(): void {
    if (this.loading()) {
      return;
    }
    if (this.type() == 'submit' && this.formGroupDirective) {
      const formGroup = this.formGroupDirective.form;
      this.validateForm(formGroup);
    }
    this.buttonClick.emit();
  }

  validateForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }
}
