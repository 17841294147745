import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '../../../../../shared/components/button/button.component';
import { OutlineTextInputComponent } from '../../../../../shared/components/outline-text-input/outline-text-input/outline-text-input.component';
import { AppSvgIconComponent } from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import { TopBarComponent } from '../../../top-bar/top-bar.component';
import { FooterComponent } from '../../../footer/footer.component';
import { BaseComponent } from "../../../../../shared/base/base-component";
import { RequestDemoService } from '../data/request-demo.service';

import { State } from "../../../../../shared/base/base-state";

@Component({
  selector: 'app-request-demo',
  standalone: true,
  templateUrl: './request-demo.component.html',
  styleUrl: './request-demo.component.scss',
  imports: [ReactiveFormsModule, CommonModule, ButtonComponent, OutlineTextInputComponent, AppSvgIconComponent, TopBarComponent, FooterComponent]
})
export class RequestDemoComponent extends BaseComponent implements OnInit {

  requestDemoService = inject(RequestDemoService);
  formBuilder = inject(FormBuilder);

  requestDemoForm!: FormGroup;

  requestdemoState = new State<any>();



  ngOnInit(): void {
    this.requestDemoForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: [''],
      phoneNumber: ['', Validators.required],
      email: ['', Validators.email],
      message: ['', Validators.required],
    });
  }


  onRequestDemoSubmit() {
    let request = this.requestDemoForm.value;
    this.executeRequest({
      state: this.requestdemoState,
      request: this.requestDemoService.submitRequestDemo(request),
      onSuccess: response => {
        this.requestDemoForm.reset();
        this.router.navigate(['/request-demo-success']);
      },
      handleSuccess: true,
      successMessage: 'Thank you for requesting a demo, we will get back to you as soon as possible.',
    });
  }
}
