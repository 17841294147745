import {Component, input} from '@angular/core';
import {TooltipDirective} from "../../../shared/components/tooltip/tooltip.directive";

@Component({
  selector: 'app-service',
  standalone: true,
  imports: [
    TooltipDirective
  ],
  templateUrl: './service.component.html',
  styleUrl: './service.component.scss'
})
export class ServiceComponent {
  title = input<string>();

  info = input<string>();
}
