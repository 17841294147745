<div class="container mx-auto px-4 py-44" id="services">

  <div class="grid grid-cols-1 md:grid-cols-2 gap-8">

    <div class="flex flex-col gap-8">
      <p class="text-xl text-blue-900">Services</p>
      <div class="col-span-2 flex flex-col gap-6">
        <p class="text-body1 max-w-2xl text-neutral-500">We help trucking companies and drivers stay compliant and up to
          date with dot and fmcsa regulations. Our highly trained and professional consultants are extremely
          knowledgeable and will give you the tools you need to pass any dot or fmcsa audit or inspection.</p>
        <p class="w-min text-nowrap text-sm text-blue-900 bg-white px-3 py-2 border border-blue-900 rounded-md ">
          Contact
          us</p>
      </div>
    </div>

    <div class="flex justify-start md:justify-center gap-4">
      <div class="flex flex-col gap-5 col-span-1">
        <app-service title="90 Day Periodic Inspection"></app-service>
        <app-service title="45 Day Periodic Inspection"
                     info="Coach Bus, School bus, Tour bus, Youth bus, and SPAB bus."></app-service>
        <app-service title="Pre or Post DOT/CHP Audit/Inspection"></app-service>
        <app-service title="Driver Pull Notice"></app-service>
        <app-service title="Driver Log Book Audit"></app-service>
      </div>
      <div class="flex flex-col gap-5 col-span-1">
        <app-service title="CA Number"></app-service>
        <app-service title="MCP Permit"></app-service>
        <app-service title="DOT Number"></app-service>
        <app-service title="MC Number"></app-service>
      </div>
    </div>
  </div>
</div>
