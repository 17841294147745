import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {SvgIconComponent} from 'angular-svg-icon';
import {AppSvgIconComponent} from '../../shared/components/app-svg-icon/app-svg-icon.component';
import {ButtonComponent} from "../../shared/components/button/button.component";
import {TopBarComponent} from "./top-bar/top-bar.component";
import {HeroComponent} from "./hero/hero.component";
import {ServicesComponent} from "./services/services.component";
import {FeaturesComponent} from "./features/features.component";
import {TryOurPlatformComponent} from "./try-our-platform/try-our-platform.component";
import {FooterComponent} from "./footer/footer.component";
import {RequestDemoComponent} from "./try-our-platform/request-demo/presentation/request-demo.component";
import {Subscription} from "rxjs";
import {LayoutService} from "../layouts/layout/layout.service";

@Component({
  selector: 'app-your-safety-compliance-landing-page',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [
    AppSvgIconComponent, SvgIconComponent,
    ButtonComponent, TopBarComponent, HeroComponent, ServicesComponent, FeaturesComponent, TryOurPlatformComponent, FooterComponent,
    RequestDemoComponent
  ]
})
export class HomeComponent implements OnInit, OnDestroy {

  layoutService = inject(LayoutService);

  private scrollSubscription!: Subscription;

  ngOnInit() {
    this.scrollSubscription = this.layoutService.scrollToSection$.subscribe(
      sectionId => this.scrollToSection(sectionId)
    );
  }

  ngOnDestroy() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  scrollToSection(sectionId: string) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({behavior: 'smooth'});
    }
  }
}
