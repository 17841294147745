import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {ResponsiveHelperComponent} from "./shared/components/responsive-helper/responsive-helper.component";
import {TopBarComponent} from "./features/home/top-bar/top-bar.component";
import {FooterComponent} from "./features/home/footer/footer.component";
import {ToasterComponent} from "./shared/components/toast/components/toaster/toaster.component";
import {LoaderComponent} from "./shared/components/loader/loader.component";
import { TryOurPlatformComponent } from './features/home/try-our-platform/try-our-platform.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ResponsiveHelperComponent, TopBarComponent, FooterComponent, ToasterComponent, LoaderComponent,TryOurPlatformComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'ysc-landing-web';
}
