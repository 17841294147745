<div class="container mx-auto px-4 py-14 flex flex-col gap-6">
  <div class="flex flex-col items-center gap-2">
    <p class="text-body2 text-primary-400">Request a Demo</p>
    <p class="text-body1 text-neutral-400">Kindly fill he form to Request for a demo</p>
  </div>
  <div class="flex justify-center items-center space-y-4 w-auto ">
    <form class="bg-white rounded w-full max-w-[700px]" [formGroup]="requestDemoForm"
      (ngSubmit)="onRequestDemoSubmit()">
      <div class="flex flex-col sm:flex-row sm:gap-4 justify-center items-center">
        <app-outline-text-input class="w-full" formControlName="firstName" [label]="'First Name'"
          [placeholder]="'Ex: John'" [fullWidth]="true">
        </app-outline-text-input>
        <app-outline-text-input class="w-full" formControlName="lastName" [label]="'Last Name'"
          [placeholder]="'Ex: Bravo'" [fullWidth]="true"></app-outline-text-input>
      </div>
      <app-outline-text-input formControlName="phoneNumber" [label]="'Phone Number'"
        [placeholder]="'Ex: (408) 482-XXXX'" [fullWidth]="true"></app-outline-text-input>

      <app-outline-text-input formControlName="email" [label]="'Email'" [placeholder]="'Ex. john.bravo@company.com'"
        [fullWidth]="true"></app-outline-text-input>

      <app-outline-text-input formControlName="message" [label]="'Message'"
        [placeholder]="'Tell us about yourself so we help you based on your needs'"
        [fullWidth]="true"></app-outline-text-input>

      <app-button type="submit" [loading]="requestdemoState.loading()" [buttonColor]="'bg-primary-500'"
        [fullWidth]="true">Submit
      </app-button>
    </form>
  </div>

</div>