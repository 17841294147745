import { Component, inject } from '@angular/core';
import { ButtonComponent } from "../../../shared/components/button/button.component";
import { Router } from '@angular/router';

@Component({
    selector: 'app-page-not-found',
    standalone: true,
    templateUrl: './page-not-found.component.html',
    styleUrl: './page-not-found.component.scss',
    imports: [ButtonComponent]
})
export class PageNotFoundComponent {

    router = inject(Router); 

    onHomeButtonClick() {
        this.router.navigate(["/"]);
    }

}
