import {Component, ElementRef, inject, input, Input} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {openLink} from "../../../../shared/utils/html-utils";
import {Router} from "@angular/router";

@Component({
  selector: 'app-footer-link',
  standalone: true,
  imports: [],
  templateUrl: './footer-link.component.html',
  styleUrl: './footer-link.component.scss'
})
export class FooterLinkComponent {

  router = inject(Router);

  document = inject(DOCUMENT);
  elementRef = inject(ElementRef);

  url = input<string | null>();
  routeUrl = input<string | null>();
  openInNewTab = input(false);

  onUrlClicked() {
    if (this.url()) {
      openLink(this.url()!, this.openInNewTab(), this.document, this.elementRef);
    }

    if (this.routeUrl()) {
      this.router.navigate(['/terms-and-conditions']);
    }
  }
}
