import { Component } from '@angular/core';
import { AppSvgIconComponent } from '../../../../shared/components/app-svg-icon/app-svg-icon.component';
@Component({
    selector: 'app-request-demo-success',
    standalone: true,
    templateUrl: './request-demo-success.component.html',
    styleUrl: './request-demo-success.component.scss',
    imports: [AppSvgIconComponent]
})
export class RequestDemoSuccessComponent {

}
