import { Component } from '@angular/core';
import { FooterLinkComponent } from "./footer-link/footer-link.component";
import { AppSvgIconComponent } from '../../../shared/components/app-svg-icon/app-svg-icon.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    FooterLinkComponent
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

}
