<div class="container-fluid mx-auto px-4 py-24 relative" id="features" >
  <div class="absolute -z-10 top-0 inset-x-0 bg-primary-400 h-[70%]"></div>
  <div class="container mx-auto">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">

      <div class="flex flex-col gap-6 items-center justify-start lg:items-start">
        <p class="text-h6 text-white text-center lg:text-left">Features</p>
        <p class="text-body2 max-w-md text-white text-center lg:text-left">California Highway Patrol and the Department
          of Transportation
          compliance
          fines and penalties are costly and can result in the loss of your business. We are experienced professionals
          who will help you understand how to stay compliant and survive these audits and inspections.</p>
        <app-button> Know more</app-button>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <div class="flex flex-col gap-4 w-full h-64 bg-white rounded-lg shadow-2 px-6 py-10">
          <div class="w-min p-2 rounded-md bg-blue-500">
            <app-svg-icon class="text-white h-10 w-10" src="assets/icons/card-account-details.svg"></app-svg-icon>
          </div>
          <h3 class="text-body2 font-semibold">Driver Management</h3>
          <p class="text-neutral-500 text-caption">Efficiently manage driver profiles, including onboarding
            processes ensuring that all compliances are recorded.</p>
        </div>
        <div class="flex flex-col gap-4 w-full h-64 bg-white rounded-lg shadow-2 px-6 py-10">
          <div class="w-min p-2 rounded-md bg-green-500">
            <app-svg-icon class="text-white h-10 w-10" src="assets/icons/compliance-management.svg"></app-svg-icon>
          </div>
          <h3 class="text-body2 font-semibold">Compliance Management</h3>
          <p class="text-neutral-500 text-caption">Efficiently manage driver profiles, including onboarding
            processes ensuring that all compliances are recorded.</p>
        </div>
        <div class="flex flex-col gap-4 w-full h-64 bg-white rounded-lg shadow-2 px-6 py-10">
          <div class="w-min p-2 rounded-md bg-blue-800">
            <app-svg-icon class="text-white h-10 w-10" src="assets/icons/fleet-management.svg"></app-svg-icon>
          </div>
          <h3 class="text-body2 font-semibold">Fleet Management</h3>
          <p class="text-neutral-500 text-caption">Efficiently manage driver profiles, including onboarding
            processes ensuring that all compliances are recorded.</p>
        </div>
        <div class="flex flex-col gap-4 w-full h-64 bg-white rounded-lg shadow-2 px-6 py-10">
          <div class="w-min p-2 rounded-md bg-orange-500">
            <app-svg-icon class="text-white h-10 w-10" src="assets/icons/alert-system.svg"></app-svg-icon>
          </div>
          <h3 class="text-body2 font-semibold">Alert System</h3>
          <p class="text-neutral-500 text-caption">Efficiently manage driver profiles, including onboarding
            processes ensuring that all compliances are recorded.</p>
        </div>
      </div>
    </div>
  </div>
</div>
