import {Component, inject} from '@angular/core';
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {RouterLink} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {LayoutService} from "../../layouts/layout/layout.service";
import {BaseComponent} from "../../../shared/base/base-component";

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    ButtonComponent,
    RouterLink
  ],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss'
})
export class TopBarComponent extends BaseComponent {

  layoutService = inject(LayoutService);

  onLoginClicked() {
    window.open(`${environment.appUrl}/login`);
  }

  onSignupClicked() {
    window.open(`${environment.appUrl}/sign-up`);
  }

  onNavClicked(sectionId: string) {
    this.layoutService.scrollTo(sectionId);
  }
}
