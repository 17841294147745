import { Component, Input, inject, input } from '@angular/core';
import { ButtonComponent } from "../../../shared/components/button/button.component";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";

@Component({
  selector: 'app-try-our-platform',
  standalone: true,
  imports: [
    ButtonComponent
  ],
  templateUrl: './try-our-platform.component.html',
  styleUrl: './try-our-platform.component.scss'
})
export class TryOurPlatformComponent {

  tryPlatformForm!: FormGroup;
  successmessage: string = '';
  showContent: boolean = true;
  isEditClicked: boolean = false;
  successedit: string = '';
  deleteClicked: string = '';
  buttontext: string = 'Delete';
  submit = this.onFormSubmitted;
  color: string = 'red';
  nameControl!: FormControl<any>;
  formControl: any;
  @Input() label: string = '';

  formBuilder = inject(FormBuilder);
  httpClient = inject(HttpClient);
  router = inject(Router);
  ButtonComponent: any;



  onFormSubmitted() {

    this.router.navigate(['/request-for-demo']);

  }
  get isRequired(): boolean {
    return this.formControl.hasError('required') && (this.formControl.touched || this.formControl.dirty);
  }

  get isFirstNameRequired(): boolean {
    return this.nameControl.hasError('required') && (this.nameControl.touched || this.nameControl.dirty);
  }

}


