import { Injectable } from '@angular/core';
import { URLs } from '../../../../../core/api/urls';
import { BaseApiService } from '../../../../../shared/base/base-api.service';


@Injectable({
  providedIn: 'root'
})
export class  RequestDemoService extends BaseApiService {

  submitRequestDemo(request: any) {
    return this.post(URLs.requestDemo, request);
  }
}
