import { Component } from '@angular/core';
import { AppSvgIconComponent } from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";

@Component({
    selector: 'app-contact-us-success',
    standalone: true,
    templateUrl: './contact-us-success.component.html',
    styleUrl: './contact-us-success.component.scss',
    imports: [AppSvgIconComponent]
})
export class ContactUsSuccessComponent {

}
