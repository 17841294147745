import {AfterContentInit, Component, EventEmitter, inject, input, output, Output} from '@angular/core';
import { FormControl, NgControl, ReactiveFormsModule } from "@angular/forms";
import { NgClass } from "@angular/common";
import { BaseControlValueAccessor } from '../../../base/base-control-value-accessor';
import { AppSvgIconComponent } from "../../app-svg-icon/app-svg-icon.component";
import { HumanizeFormMessagesPipe } from '../../humanize-form-messages.pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-outline-text-input',
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    HumanizeFormMessagesPipe,
    AppSvgIconComponent,
    CommonModule
  ],
  templateUrl: './outline-text-input.component.html',
  styleUrl: './outline-text-input.component.scss'
})
export class OutlineTextInputComponent extends BaseControlValueAccessor implements AfterContentInit {
  appearance = input<'fill' | 'outline'>('outline');
  type = input<'text' | 'email' | 'password' | 'number' | 'search' | 'tel' | 'url'>('text');
  viewType = input<'text' | 'text-area'>('text');
  iconSrc = input<string | null>();
  actionIcon = input<string | null>();
  label = input<string | null>();
  fullWidth = input<boolean>(false);
  placeholder = input<string>('');
  errorMessages = input<{ [key: string]: string }>({});

  @Output()
  changeValue: EventEmitter<any> = new EventEmitter<any>();

  actionIconClicked = output();

  ngControl = inject(NgControl, { optional: true, self: true });
  override formControl: any;

  constructor() {
    super();
    if (this.ngControl) {
      this.ngControl!.valueAccessor = this;
    }
  }

  ngAfterContentInit(): void {
    let formControl = this.ngControl?.control as FormControl;
    if (formControl) {
      this.formControl = this.ngControl?.control as FormControl;
    }
  }

  onValueChange(event: any) {
    const target = event.target as HTMLInputElement;
    this.onChange(target.value);
    this.changeValue.emit(target.value);
  }

  getClass() {
    let cls = '';
    if (this.iconSrc()) {
      cls = cls + 'pr-3 pl-10';
    } else {
      cls = cls + 'px-3';
    }

    if (this.fullWidth()) {
      cls = cls + ' ' + 'w-full';
    }

    return cls;
  }
  get isRequired(): boolean {
    return this.formControl.hasError('required') && (this.formControl.touched || this.formControl.dirty)
  }

  onActionClicked() {
    this.actionIconClicked.emit();
  }
}
